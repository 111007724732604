<script setup lang="ts">
import type { ConcreteComponent, ComputedOptions, FunctionalComponent, MethodOptions } from 'vue';

export interface MenuItem {
  label: string;
  id: string;
  href?: string;
  icon?: string;
  onClick?: () => void;
  target?: '_blank' | '_self' | '_parent' | '_top';
  children?: MenuItem[];
  element?: string | ConcreteComponent<{}, any, any, ComputedOptions, MethodOptions> | FunctionalComponent<{}, any, any>;
  command?: () => void;
}

interface itemClickEvt extends Event {
  target: HTMLAnchorElement;
}

const props = defineProps<{
    item: MenuItem;
    isActive: boolean;
  }>();

const { item, isActive } = toRefs(props);

const isExpanded = ref(false);
const childMenu = computed(() => {
  return props.item.children?.map(child => ({
    label: child.label,
    id: child.id,
    url: child.href,
    command: () => {
      if (!child.command) {
        return;
      }

      child.command();
      closeMenu();
    },
  } as MenuItem));
});

const itemClick = (e: itemClickEvt) => {
  if (item.value.children?.length || item.value.onClick) {
    e.preventDefault();

    if (item.value.onClick) {
      item.value.onClick();
    }

    e.target.focus();

    isExpanded.value = !isExpanded.value;
  }
};

const closeMenu = () => {
  isExpanded.value = false;
};
</script>

<template>
  <li
    :class="{
      'm-navigation-item': true,
      'parent': item.children?.length,
      active: isActive
    }"
  >
    <Component
      :is="item.element || 'a'"
      class="nav-link"
      :href="item.href ? item.href : `#link-${item.id}`"
      :target="item.target"
      @click="itemClick"
    >
      {{item.label}}

      <MIcon
        v-if="item.children?.length"
        class="parent-indicator"
        :icon="isExpanded ? 'chevron-up' : 'chevron-down'"
      />

      <MIcon
        v-if="item.icon"
        class="icon"
        :icon="item.icon"
      />
    </Component>
    <Menu
      v-if="item.children?.length && isExpanded"
      :model="childMenu"
    />
    <div
      v-if="item.children?.length && isExpanded"
      class="click-out"
      @click="closeMenu"
    />
  </li>
</template>

<style lang="scss">
  @import '../../assets/styles/utility.scss';
  @import "../../assets/styles/font-styles.scss";

  .m-navigation-item {
    position: relative;
    display: block;

    .nav-link,
    .nav-placeholder {
      @include f-20-bold;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3em;
      width: 100%;
      padding: 1em 2em;
      white-space: nowrap;
      text-decoration: none;
      color: var(--palette-neutral-60);
      cursor: pointer;

      @include breakpoint(large) {
        @include f-16-bold;

        padding: 0.5em 1em;
      }

      &:hover {
        color: var(--palette-neutral-90);
      }

      &:focus {
        outline: none;
      }

      .parent-indicator {
        font-size: 0.75em;
        display: inline-block;
        margin-right: auto;
      }
    }

    .p-menu {
      position: absolute;
      top: calc(100% + 1.2em - 1em);
      left: 0;
      z-index: 10;
      box-shadow: 0 2em 2em rgba(16, 42, 67, .1);
    }

    &.active {
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 0.625em;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 0 1.762em 1.762em 0;
        background-color: var(--palette-blue-50);

        @include breakpoint(large) {
          width: 4em;
          height: 0.75em;
          top: unset;
          bottom: calc(-1em - 0.65em + 0.5px);
          left: 50%;
          transform: translateX(-50%);
          border-radius: 2.313em 2.313em 0 0;
        }
      }

      .nav-link,
      .nav-placeholder {
        color: var(--palette-neutral-100);
      }
    }

    &.parent {
      > * {
        cursor: pointer;
        gap: 0.3em;
      }
    }
  }

  .click-out {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
  }
</style>
