<script setup lang="ts">
import { useOnline } from '@vueuse/core';

const route = useRoute();

const isOnline = useOnline();
const { dispatchMessage } = useSystemMessages();

const showFlagManager = computed(() => {
  return route.query.flags !== undefined;
});

watch(isOnline, (newValue: boolean) => {
  if (newValue) {
    dispatchMessage('Back online.', 'success', 'Your browser is back online.', 10000);
    return;
  }

  dispatchMessage('You are offline.', 'warn', 'Your browser went offline, please check your internet connection.', 15000);
});
</script>

<template>
  <div class="layout-application">
    <AppHeader />
    <SystemMessages />

    <slot />

    <FeatureFlagManager v-if="showFlagManager" />
  </div>
</template>

<style lang="scss">
  .layout-application {
    .system-messages {
      position: fixed;
      top: 5em;
      left: var(--component-margin);
      right: var(--component-margin);
      margin: 0 auto;
      z-index: 99000;
    }
  }
</style>
